import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/camera',
    name: 'Camera',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/odeum',
    name: 'Odeum',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/stat',
    name: 'Stat',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Statistics.vue')
  },

  {
    path: '/begin',
    name: 'Begin',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Begin.vue')
  },

  {
    path: '/active',
    name: 'Active',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Active.vue')
  },

  {
    path: '/notes',
    name: 'Notes',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/sell',
    name: 'Sell',
    component: () => import(/* webpackChunkName: "about" */ '../views/kassa/Sell.vue')
  },

  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/operations',
    name: 'Operations',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/refund',
    name: 'Refund',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },

  {
    path: '/messages',
    name: 'Messages',
    component: () => import(/* webpackChunkName: "about" */ '../views/scanner/Scan.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
